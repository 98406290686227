// window.Popper = require('popper.js');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

// window.$ = window.jQuery = require('jquery');

// require('bootstrap-v4-rtl');

// smooth scroll
const links = document.querySelectorAll('a[href*="#"]');
 
for (const link of links) {
  link.addEventListener("click", clickHandler);
}
 
function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;
 
  scroll({
    top: offsetTop-80,
    behavior: "smooth"
  });
}

// back to top show.hide
myID = document.getElementById("back-top");

var myScrollFunc = function() {
  var y = window.scrollY;
  if (y >= 800) {
    myID.className = "d-fixed"
  } else {
    myID.className = "d-none"
  }
};

window.addEventListener("scroll", myScrollFunc);